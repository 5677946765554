import React from 'react';
import styles from './AboutUs.module.css';
import icon1 from '../assets/icon1.png'; // Example icon file path
import icon2 from '../assets/icon2.png'; // Example icon file path
import icon3 from '../assets/icon3.png'; // Example icon file path

const AboutUs = () => {
    return (
        <div className={styles.aboutUsContainer}>
            <h2 className={styles.heading}>O nas - Fit-Fusion Studio</h2>
            <div className={styles.heading}>
                <h3>Zalety dołączenia do nas</h3>
                <div className={styles.advantages}>
                    <div className={styles.advantageItem}>
                        <img src={icon1} alt="Nowoczesny sprzęt" className={styles.icon} />
                        <p>Nowoczesny sprzęt</p>
                    </div>
                    <div className={styles.advantageItem}>
                        <img src={icon2} alt="Spersonalizowane treningi" className={styles.icon} />
                        <p>Spersonalizowane programy treningowe</p>
                    </div>
                    <div className={styles.advantageItem}>
                        <img src={icon3} alt="Wsparcie społeczności" className={styles.icon} />
                        <p>Wsparcie dynamicznej społeczności</p>
                    </div>
                </div>
            </div>
            <p>
                Witamy w <strong>Fit-Fusion Studio</strong>, miejscu docelowym dla wszystkich, którzy chcą przekształcić swoje zdrowie i dobre samopoczucie w pięknym mieście Wrocław. Nasze nowoczesne obiekty i różnorodne programy fitness zostały zaprojektowane tak, aby pomóc Ci osiągnąć swoje cele, niezależnie od tego, czy jesteś początkującym, czy doświadczonym sportowcem.
            </p>
            <p>
                Położone w sercu Wrocławia, Fit-Fusion Studio oferuje unikalne połączenie nowoczesnych udogodnień i wspierającej atmosfery społeczności. Nasi certyfikowani trenerzy są pasjonatami pomagania Ci w osiąganiu sukcesów i są przygotowani do projektowania planów treningowych dostosowanych do indywidualnych potrzeb i preferencji.
            </p>
            <p>
                Dołącz do naszej dynamicznej społeczności i doświadcz korzyści płynących ze zdrowszego stylu życia w jednym z najpiękniejszych miast Polski. Dzięki elastycznym opcjom członkostwa i przyjaznej atmosferze, nie ma lepszego miejsca, aby rozpocząć swoją podróż fitness. Odwiedź nas na <b>fit-fusionstudio.com</b> i zrób pierwszy krok w kierunku silniejszej, zdrowszej wersji siebie!
            </p>
            
           
        </div>
    );
};

export default AboutUs;
