import React, { createContext, useContext } from 'react';

const SiteContext = createContext();

export const SiteProvider = ({ children }) => {
  const siteInfo = {
    siteName: "fit-fusionstudio.com",
    slogan: "Twoje zdrowie, naszą misją",
    info: "Odkryj swoje fitness schronienie w sercu Wrocławia!",
    thankYouProduct: "Dziękujemy za zainteresowanie naszym produktem. Cieszymy się, że możemy wspierać Cię w Twojej fitness podróży!",
    thankYouSubscription: "Dziękujemy za zapisanie się do naszego newslettera. Śledź nas dalej, aby uzyskać specjalne oferty i wskazówki dotyczące fitnessu!",
    thankYouOrder: "Dziękujemy za zamówienie. Twoja podróż do doskonałości fitness zaczyna się tutaj!",
  };

  return (
    <SiteContext.Provider value={siteInfo}>
      {children}
    </SiteContext.Provider>
  );
};

// Create a custom hook to use the SiteContext
export const useSite = () => {
  return useContext(SiteContext);
};
