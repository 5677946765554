import React from 'react';
import styles from './Footer.module.css'; // Import the CSS module for styling
import { useSite } from '../context/SiteContext';
import socials from '../data/socialsData'; // Import the socials array
import { Link } from 'react-router-dom';

const Footer = () => {
  const { siteName } = useSite();
  const currentYear = new Date().getFullYear(); // Get the current year

  return (
    <footer className={styles.footer}>
      <div className={styles.linksRow}>
        <Link to="/terms" className={styles.footerLink}>Warunki i zasady</Link>
        <Link to="/privacy" className={styles.footerLink}>Polityka prywatności</Link>
      </div>
      
      <div className={styles.socialIconsRow}>
        {socials.map(social => (
          <a key={social.id} href={social.link} className={styles.socialIcon}>
            <img src={social.image} alt={`Ikona społecznościowa ${social.id}`} />
          </a>
        ))}
      </div>
      
      <div className={styles.footerContact}>
        <h3>Kontakt</h3>
        <p className={styles.footerContact}>Adres: ul. Piękna 24, 50-506 Wrocław, Polska</p>
        <p className={styles.footerContact}>Telefon: +48 123 456 789</p>
        <p className={styles.footerContact}>Email: kontakt@fit-fusionstudio.com</p>
      </div>

      <div className={styles.footerCopyright}>
        {siteName} &copy; {currentYear} <br/> Wszelkie prawa zastrzeżone.
      </div>
    </footer>
  );
};

export default Footer;
