import React from 'react';
import { useSite } from '../context/SiteContext';
import styles from './Privacy.module.css'; // Assuming you have a CSS module for styling

const Privacy = () => {
  const { siteName } = useSite();

  return (
    <div className={styles.privacyInfo}>
      <h1>ZOBOWIĄZANIE DO PRYWATNOŚCI</h1>
      <p>W {siteName} priorytetem jest Twoja prywatność i bezpieczeństwo Twoich danych. Ten dokument przedstawia nasze zobowiązanie do ochrony Twoich danych oraz wyjaśnia praktyki, które akceptujesz, korzystając z naszych usług i strony internetowej pod adresem <strong>fit-fusionstudio.com</strong>.</p>

      <h2>1. ZBIERANIE INFORMACJI</h2>
      <p><strong>Dane osobowe:</strong> Podczas rejestracji członkostwa i rezerwacji zajęć zbieramy niezbędne szczegóły, takie jak imię i nazwisko, adres e-mail, adres pocztowy i dane dotyczące płatności.</p>
      <p><strong>Dane interakcji:</strong> Korzystamy z technologii, takich jak pliki cookie, aby śledzić Twoje interakcje z naszą stroną i rejestrować informacje, takie jak adres IP i typ przeglądarki, w celu poprawy doświadczenia użytkownika.</p>

      <h2>2. WYKORZYSTANIE INFORMACJI</h2>
      <p><strong>Zarządzanie członkostwem:</strong> Twoje dane osobowe są wykorzystywane do zarządzania członkostwem, organizacji zajęć i utrzymywania komunikacji podczas interakcji z nami.</p>
      <p><strong>Komunikacja:</strong> Używamy Twoich danych kontaktowych do wysyłania potwierdzeń zajęć, aktualizacji harmonogramu i ważnych ogłoszeń dotyczących członkostwa.</p>
      <p><strong>Dostosowanie:</strong> Za Twoją zgodą, personalizujemy Twoje doświadczenie na stronie i oferujemy specjalne rekomendacje i usługi w oparciu o poprzednie interakcje i preferencje.</p>
      <p><strong>Rozwój usług:</strong> Analizujemy zebrane dane, aby stale poprawiać naszą stronę i usługi, zapewniając lepsze doświadczenia dla wszystkich członków.</p>

      <h2>3. UJAWNIANIE INFORMACJI</h2>
      <p><strong>Operacje biznesowe:</strong> Twoje dane mogą być udostępniane stronom trzecim, które pomagają w operacjach, takich jak zarządzanie zajęciami i przetwarzanie płatności, w ramach ścisłych umów o poufności.</p>
      <p><strong>Obowiązki prawne:</strong> Możemy ujawnić Twoje dane, aby chronić nasze prawa, zapewnić bezpieczeństwo naszych usług lub publiczne bezpieczeństwo, jeśli wymagają tego przepisy prawne.</p>

      <h2>4. ŚRODKI BEZPIECZEŃSTWA</h2>
      <p>Nasze protokoły bezpieczeństwa są zaprojektowane, aby chronić Twoje dane osobowe przed nieautoryzowanym dostępem i nadużyciem. Prosimy jednak pamiętać o ryzyku inherentnym w transmisji informacji online.</p>

      <h2>5. TWOJE PREFERENCJE PRYWATNOŚCI</h2>
      <p><strong>Rezygnacja z wiadomości promocyjnych:</strong> W każdej chwili możesz zrezygnować z otrzymywania wiadomości promocyjnych, korzystając z linków dostarczonych w naszych komunikatach lub kontaktując się bezpośrednio z nami.</p>
      <p><strong>Pliki cookie:</strong> Możesz zarządzać swoimi preferencjami dotyczącymi plików cookie w ustawieniach przeglądarki, kontrolując, jak Twoje dane interakcji są zbierane.</p>

      <h2>6. BEZPIECZEŃSTWO DZIECI</h2>
      <p>Nie zbieramy świadomie danych od osób poniżej 16 roku życia. Wszelkie przypadkowo zebrane informacje zostaną niezwłocznie usunięte z naszych systemów.</p>

      <h2>7. AKTUALIZACJE POLITYKI</h2>
      <p>Okresowo aktualizujemy naszą Politykę Prywatności, aby odzwierciedlała nowe praktyki. Zalecamy regularne przeglądanie polityki, ponieważ dalsze korzystanie z naszych usług po aktualizacjach oznacza akceptację zmian.</p>

      <p>Jeśli masz pytania dotyczące naszych praktyk prywatności lub chcesz uzyskać więcej informacji, skontaktuj się z nami pod adresem <a href={`mailto:kontakt@fit-fusionstudio.com`}>kontakt@fit-fusionstudio.com</a>. Jesteśmy zaangażowani w szybkie odpowiadanie na Twoje pytania i ochronę Twoich praw do prywatności.</p>
    </div>
  );
};

export default Privacy;
