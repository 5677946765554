import React from 'react';
import { useSite } from '../context/SiteContext';
import styles from './Terms.module.css'; // Assuming you have a CSS module for styling

const Terms = () => {
  const { siteName } = useSite();

  return (
    <div className={styles.termsInfo}>
      <h1>WARUNKI ŚWIADCZENIA USŁUG</h1>
      <p>Witamy w {siteName}! Uzyskując dostęp i korzystając z naszej strony internetowej pod adresem <strong>fit-fusionstudio.com</strong>, akceptujesz poniższe warunki. Jeśli się z nimi nie zgadzasz, prosimy o niekorzystanie z naszej strony.</p>
      
      <h2>1. PRAWA WŁASNOŚCI INTELEKTUALNEJ</h2>
      <p>Wszystkie treści na naszej stronie, w tym teksty, grafiki, loga, ikony, obrazy i filmy, są własnością {siteName} i są chronione prawem autorskim oraz prawem własności intelektualnej. Nieautoryzowane użycie lub reprodukcja są surowo zabronione.</p>
      
      <h2>2. DOZWOLONE UŻYCIE</h2>
      <p>Nasza strona jest przeznaczona do osobistego użytku w celu odkrywania naszych usług i udogodnień fitness. Nie możesz zmieniać, rozpowszechniać ani reprodukować jakiejkolwiek treści bez pisemnej zgody. Zabronione są działania, które mogą zagrozić funkcjonalności lub bezpieczeństwu naszej strony, takie jak złośliwe oprogramowanie.</p>
      
      <h2>3. DOKŁADNOŚĆ INFORMACJI</h2>
      <p>Staramy się zapewnić dokładność wszystkich informacji dotyczących naszych programów fitness, zajęć i opcji członkostwa. Jednak nie możemy zagwarantować, że wszystkie informacje są wolne od błędów. Zastrzegamy sobie prawo do poprawiania błędów w harmonogramach zajęć, cenach i dostępności bez uprzedniego powiadomienia.</p>
      
      <h2>4. CENY I TRANSAKCJE</h2>
      <p>Ceny członkostwa i usług mogą ulec zmianie i są podawane w lokalnej walucie. Oferujemy bezpieczne opcje płatności, a akceptowane metody płatności są określane podczas zakupu lub rejestracji członkostwa.</p>
      
      <h2>5. CZŁONKOSTWA I ANULOWANIA</h2>
      <p>Zakup członkostwa jest ofertą dołączenia do {siteName}, którą możemy zaakceptować lub odrzucić. Zastrzegamy sobie prawo do anulowania członkostw z powodów takich jak nieprzestrzeganie zasad klubu lub problemy z płatnościami. Dotknięci członkowie zostaną niezwłocznie powiadomieni i odpowiednio wynagrodzeni.</p>
      
      <h2>6. REZERWACJE ZAJĘĆ I ANULOWANIA</h2>
      <p>Członkowie mogą rezerwować zajęcia na naszej stronie internetowej. Anulowania muszą być dokonywane zgodnie z naszą polityką anulowania zajęć. Zastrzegamy sobie prawo do anulowania lub zmiany harmonogramu zajęć, jeśli zajdzie taka potrzeba, i niezwłocznie poinformujemy członków o takich zmianach.</p>
      
      <h2>7. LINKI ZEWNĘTRZNE</h2>
      <p>Nasza strona może zawierać linki do zewnętrznych witryn, które nie są przez nas zarządzane. Nie odpowiadamy za treści ani praktyki prywatności tych zewnętrznych witryn, dlatego zalecamy ostrożność podczas uzyskiwania do nich dostępu.</p>
      
      <h2>8. OGRANICZENIE ODPOWIEDZIALNOŚCI</h2>
      <p>{siteName} ani jego podmioty powiązane nie ponoszą odpowiedzialności za bezpośrednie, pośrednie, przypadkowe, specjalne lub wynikowe szkody wynikające z korzystania z naszej strony lub opierania się na dostarczonych informacjach. Nasza odpowiedzialność jest ograniczona w maksymalnym zakresie dozwolonym przez prawo.</p>
      
      <h2>9. AKCEPTACJA WARUNKÓW</h2>
      <p>Warunki te mogą być zmieniane bez wcześniejszego powiadomienia. Kontynuowanie korzystania z naszej strony po aktualizacjach oznacza akceptację zmienionych warunków. Jeśli masz jakiekolwiek pytania dotyczące tych warunków, skontaktuj się z nami pod adresem <a href={`mailto:kontakt@fit-fusionstudio.com`}>kontakt@fit-fusionstudio.com</a>.</p>
    </div>
  );
};

export default Terms;
