import React from 'react';
import styles from './Tariffs.module.css';

const Tariffs = () => {
    const tariffs = [
        {
            name: "Plan Podstawowy",
            description: "Dostęp do całego sprzętu i obiektów siłowni w godzinach poza szczytem. Idealny dla osób rozpoczynających swoją przygodę z fitnessem."
        },
        {
            name: "Plan Standardowy",
            description: "Pełny dostęp do obiektów siłowni, zajęć grupowych oraz planów treningu personalnego. Idealny dla entuzjastów fitness szukających różnorodności i elastyczności."
        },
        {
            name: "Plan Premium",
            description: "Nieograniczony dostęp do wszystkich obiektów, zajęć premium i sesji treningowych jeden na jeden z naszymi ekspertami. Zaprojektowany dla tych, którzy poszukują najwyższego poziomu doświadczeń fitness."
        },
        {
            name: "Plan VIP",
            description: "Ekskluzywny dostęp do wszystkich obiektów, treningi personalizowane i priorytetowa rezerwacja na wszystkie zajęcia i wydarzenia. Dla tych, którzy chcą najlepszych z najlepszych."
        }
    ];

    return (
        <div className={styles.tariffsContainer}>
            <h2>Nasze Plany</h2>
            <div className={styles.tariffList}>
                {tariffs.map((tariff, index) => (
                    <div key={index} className={`${styles.tariffItem} ${styles[`tariff${index}`]}`}>
                        <h3>{tariff.name}</h3>
                        <p>{tariff.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Tariffs;
