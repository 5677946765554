import React, { useState, useEffect } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { useSite } from '../context/SiteContext';
import styles from './Hero.module.css';

import image1 from '../assets/hero-img.webp';
import SubscriptionForm from './SubscriptionForm';

const Hero = () => {
  const { slogan, info } = useSite();

  return (
    <section className={styles.hero}>
    <div className={styles.imageSection}>
      <img className={styles.image} src={image1}></img>
    </div>
 
      <div className={styles.content}>
        <h1 className={styles.slogan}>{slogan}</h1>
        <p className={styles.description}>{info}</p>
        <div className={styles.buttons}>
         <SubscriptionForm/>
         
        </div>
        
      </div>
    </section>
  );
};

const calculateFixedHeaderHeight = () => {
  const headerHeight = 0.13 * window.innerHeight; // 13vh equivalent in pixels
  return headerHeight;
};

export default Hero;