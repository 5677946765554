import React from 'react';
import styles from './Team.module.css';
import trainers1 from '../assets/trainers1.webp';
import trainers2 from '../assets/trainers2.webp';
import trainers3 from '../assets/trainers3.webp';
import Carousel from './Carousel';

const Team = () => {
    const slides = [
        trainers1,
        trainers2,
        trainers3,
    ];

    return (
        <div className={styles.aboutUs}>
            <div className={styles.imageSection}>
                <Carousel slides={slides} />
            </div>
            <div className={styles.textSection}>
                <h2>Zespół Fit-Fusion Studio</h2>
                <p>
                    Witamy w <strong>Fit-Fusion Studio</strong>, Twoim najlepszym miejscem na transformację zdrowia i dobrostanu w sercu Wrocławia. Nasza siłownia oferuje najnowocześniejsze obiekty i szeroką gamę programów, aby pomóc Ci osiągnąć Twoje cele.
                </p>
                <p>
                    W Fit-Fusion Studio jesteśmy dumni z naszego wybitnego zespołu trenerów, którzy są oddani wspieraniu Twojego sukcesu. Każdy z naszych trenerów nie tylko posiada certyfikaty, ale także wyjątkowe umiejętności i bogate doświadczenie w branży fitness. Specjalizują się w różnych dyscyplinach, takich jak trening siłowy, ćwiczenia kardio, elastyczność i fitness funkcjonalny.
                </p>
                <p>
                    Nasi trenerzy z pasją tworzą spersonalizowane plany treningowe dostosowane do Twoich celów i potrzeb, dzięki czemu każda sesja jest efektywna, wymagająca i przyjemna. Niezależnie od tego, czy jesteś początkującym, czy doświadczonym sportowcem, nasi trenerzy zapewnią Ci przewodnictwo i motywację na każdym kroku.
                </p>
                <p>
                    Dołącz do dynamicznej społeczności Fit-Fusion Studio i doświadcz miejsca, w którym fitness to nie tylko cel, ale styl życia. Odkryj różnorodne zajęcia i programy, które pasują do Twojego harmonogramu i preferencji. Rozpocznij swoją fitness podróż z nami już dziś i zrób pierwszy krok w kierunku zdrowszej, silniejszej wersji siebie!
                </p>
            </div>
        </div>
    );
};

export default Team;
