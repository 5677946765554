import React, { useState } from 'react';
import Modal from './Modal';
import styles from './SubscriptionForm.module.css';

const SubscriptionForm = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [tariff, setTariff] = useState('Basic');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email && name && tariff) {
      setIsModalOpen(true);
      setEmail('');
      setName('');
      setTariff('Basic');
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={styles.subscriptionForm}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <h3>Zapisz się do nas</h3>
        <input
          type="text"
          placeholder="Wpisz swoje imię"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className={styles.nameInput}
          required
        />
        <input
          type="email"
          placeholder="Wpisz swój adres e-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={styles.emailInput}
          required
        />
        <select
          value={tariff}
          onChange={(e) => setTariff(e.target.value)}
          className={styles.tariffSelect}
          required
        >
          <option value="Basic">Podstawowy</option>
          <option value="Standard">Standardowy</option>
          <option value="Premium">Premium</option>
          <option value="VIP">VIP</option>
        </select>
        <button type="submit" className={styles.subscribeButton}>
        Zapisz się 
        </button>
      </form>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <h2>Dziękujemy!</h2>
        Dziękujemy za zapisanie się!
      </Modal>
    </div>
  );
};

export default SubscriptionForm;
