import React from 'react';
import styles from './Contacts.module.css';

const Contacts = () => {
    return (
        <div className={styles.contactsContainer}>
            <h2 className={styles.title}>Kontakt</h2>
            <div className={styles.contactDetails}>
                <div className={styles.contactItem}>
                    <h3>Adres</h3>
                    <p>ul. Piękna 24, 50-506 Wrocław, Polska</p>
                </div>
                <div className={styles.contactItem}>
                    <h3>Telefon</h3>
                    <p>+48 123 456 789</p>
                </div>
                <div className={styles.contactItem}>
                    <h3>Email</h3>
                    <p>kontakt@fit-fusionstudio.com</p>
                </div>
                <div className={styles.contactItem}>
                    <h3>Godziny otwarcia</h3>
                    <p>Poniedziałek - Piątek: 6:00 - 22:00</p>
                    <p>Sobota - Niedziela: 8:00 - 20:00</p>
                </div>
            </div>
        </div>
    );
};

export default Contacts;
